import "./App.css";
import Marquee from "react-fast-marquee";
import { WebsiteCarbonBadge } from "react-websitecarbon-badge";
import Fade from "react-reveal/Fade";
import ReactGA from "react-ga4";
import { useEffect } from "react";
ReactGA.initialize("G-0J644FLT25");

function App() {
  useEffect(() => {
    ReactGA.send("pageview", window.location.pathname);
  }, []);
  const date = new Date();
  const hours = date.getHours();
  let timeOfDay;
  if (hours < 12) {
    timeOfDay = "Morning";
  } else if (hours >= 12 && hours < 17) {
    timeOfDay = "Afternoon";
  } else {
    timeOfDay = "Evening";
  }

  const handleOpen = () => {
    document.querySelector(".hamburger-menu").classList.add("open-menu");
  };

  const handleClose = () => {
    document.querySelector(".hamburger-menu").classList.remove("open-menu");
  };

  return (
    <>
      <div className="bg-[#1f1f1f]">
        <div className="sticky top-0 z-50">
          <header className="text-red-400 bg-black h-20 flex items-center justify-between">
            <a href="/#home">
              <img
                src="https://i.imgur.com/KTn2CPP.png"
                className="w-32 ml-4"
                alt=""
              />
            </a>
            <div className="minsm:hidden" id="menu">
              <img
                class="hamburger"
                src="https://i.imgur.com/FjYDdZm.png"
                alt=""
                onClick={() => handleOpen()}
              />

              <aside class="hamburger-menu">
                <img
                  class="cancel"
                  src="https://i.imgur.com/3U5P8Hh.png"
                  alt=""
                  onClick={() => handleClose()}
                />
                <a href="/#home" onClick={() => handleClose()}>
                  Home
                </a>
                <a href="/#about" onClick={() => handleClose()}>
                  About
                </a>
                <a href="/#contact" onClick={() => handleClose()}>
                  Contact
                </a>
              </aside>
            </div>
            <nav className="sm:hidden" id="nav">
              <ul className="flex justify-between text-white decoration-none mr-5">
                <li className="p-4">
                  <a
                    href="#home"
                    className="hover:text-slate-600 hover:cursor-pointer"
                  >
                    Home
                  </a>
                </li>
                <li className="p-4">
                  <a
                    href="#about"
                    className="hover:text-slate-600 hover:cursor-pointer"
                  >
                    About
                  </a>
                </li>
                <li className="p-4">
                  <a
                    href="#contact"
                    className="hover:text-slate-600 hover:cursor-pointer"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </header>
        </div>
        <div className="top-0" id="home" />
        <div className="mt-20 text-white sm:text-xs">
          <div>
            <div className="flex md:flex-col md:items-center sm:flex-col sm:items-center justify-center md:text-center sm:text-center">
              <div className="flex justify-center">
                <Fade left>
                  <img
                    src="https://i.imgur.com/NNbM0Z1.jpg"
                    alt=""
                    className="w-80 rounded-[50px]"
                  />
                </Fade>
              </div>
              <Fade right>
                <div className="flex justify-center">
                  <div className="sm:m-0 sm:mt-5 mt-10 ml-10 text-3xl sm:w-3/4 sm:text-[25px] sm:flex-col sm:items-center">
                    <div className="">Good {timeOfDay}! 👋 </div>
                    <br />
                    <div className="text-xl">
                      Dan is currently working as a<br />
                      <span
                        style={{
                          textShadow: "0 0 3px #FF0000, 0 0 5px #0000FF",
                        }}
                      >
                        Software Engineer
                      </span>{" "}
                      for{" "}
                      <span
                        style={{
                          textShadow: "0 0 3px #ffff00	, 0 0 5px #4c4c00",
                        }}
                        className="hover:scale-125 hover:cursor-pointer"
                      >
                        <a
                          href="https://www.paceteq.com"
                          target="_blank"
                          rel="noreferrer"
                        >
                          PACETEQ GmbH
                        </a>
                      </span>
                      .
                    </div>
                    <div className="text-xl mt-5">
                      Presently based in London, UK 🇬🇧
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </div>
        <div className="" id="about" />
        <Fade top>
          <div className="text-center text-white text-4xl mt-10">About</div>
        </Fade>
        <div className="flex justify-center text-white text-center text-xl">
          <div className="minxl:w-3/4 md:w-3/4 sm:w-3/4 flex justify-center items-center md:flex-col md:items-center sm:flex-col sm:items-center">
            <div className=" m-10">
              <Fade left>
                <div className="my-2">
                  Highly motivated and creative software engineer passionate
                  about collaborative work, continuous learning, and embracing
                  challenges. Dan’s journey in the industry began as a
                  full-stack developer, discovering a keen interest in backend
                  development. He is dedicated to expanding his expertise in
                  this domain while deepening his understanding of AWS
                  technologies.
                </div>
              </Fade>
              <Fade right>
                <div className="my-2">
                  He finds joy in infusing creativity into his code, excelling
                  at introducing innovative solutions to problem-solving,
                  crafting elegant code, and collaborating with similarly
                  imaginative developers.
                </div>
              </Fade>
              <Fade left>
                <div className="my-2">
                  After dedicating over 10 years of his life to pursuing a music
                  career, Dan opted to venture into technology by enrolling in
                  General Assembly's coding bootcamp. This provided him with the
                  essential tools to secure his first job in the field.
                </div>
              </Fade>
              <Fade right>
                <div className="my-2">
                  He finds immense fulfillment in imparting his coding expertise
                  and offering guidance to those seeking to transition into a
                  tech career. His role as a part-time teaching assistant with
                  General Assembly has been incredibly rewarding due to this
                  passion for sharing knowledge.
                </div>
              </Fade>
              <Fade bottom>
                <div className="my-2">
                  Beyond coding, he's an enthusiastic tennis player, avid hiker,
                  passionate traveler, and dedicated foodie!
                </div>
              </Fade>
            </div>
            <img
              src="https://i.imgur.com/16SF0mF.jpg"
              alt=""
              className="w-80 rounded-[50px] m-10"
            />
          </div>
        </div>
        <div className="" id="cv" />
        <div className="flex justify-center mt-12 mb-20 text-white text-center text-xl">
          <div className="w-3/4">
            <Fade top>
              <div className="text-4xl">CV</div>
            </Fade>
            <Fade top>
              <div className="my-2">
                To find out more about his experience and skills, feel free to
                download Dan's CV below!
              </div>
              <a
                href="https://asset.cloudinary.com/danlaurie/28f7ef4bb69658f5b0531ef0beb59407"
                className="mt-4"
                target="_blank"
                rel="noreferrer"
              >
                <div className="text-5xl m-4 hover:cursor-pointer hover:scale-125 transition duration-500 ease-in-out transform">
                  📄
                </div>
              </a>
            </Fade>
          </div>
        </div>
        <div className="" id="contact" />
        <div className="flex justify-center mb-20 text-white text-center text-xl">
          <div className="w-3/4">
            <Fade top>
              <div className="text-4xl mb-8">Contact</div>
            </Fade>
            <Fade top>
              <div className="my-2">
                If you feel like reaching out, please do so via the links below!
              </div>
            </Fade>
            <div className="flex justify-center">
              <Fade left>
                <a href="mailto:danlaurie3@gmail.com">
                  <img
                    src="https://i.imgur.com/MeOIVbs.png"
                    alt=""
                    className="w-20 h-20 m-4 hover:cursor-pointer hover:scale-125 transition duration-500 ease-in-out transform"
                  />
                </a>
              </Fade>
              <Fade right>
                <a
                  href="https://www.linkedin.com/in/danlauriedev/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="https://i.imgur.com/3569Lao.png"
                    alt=""
                    className="w-20 h-20 m-4 hover:cursor-pointer hover:scale-125 transition duration-500 ease-in-out transform"
                  />
                </a>
              </Fade>
            </div>
          </div>
        </div>
        <div className="flex justify-center m-2">
          <div className="w-full">
            <Marquee
              play={true}
              loop={0}
              gradient={true}
              gradientColor={[31, 31, 31]}
              direction={"left"}
              delay={0}
              speed={60}
            >
              <WebsiteCarbonBadge dark={true} co2="0.15" percentage="86" />
            </Marquee>
          </div>
        </div>
        <footer className="text-gray-500 bg-black w-full h-20 bottom-0 text-center flex justify-center items-center">
          <div className="">&copy; 2024 Dan Laurie</div>
        </footer>
      </div>
    </>
  );
}

export default App;
